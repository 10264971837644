import React, { useEffect } from "react";

import { IFeaturedCompanies } from "interfaces/featuredCompanies";
import { SquareButton } from "@themuse/design-system";
import getConfig from "next/config";
import dynamic from "next/dynamic";

import { IArticleTile, IRecentArticles } from "./RecentArticles";
import { ExploreCompanies, IExploreCompanies } from "./ExploreCompanies";
import { HomepageHeader } from "./HomepageHeader";

import styles from "./ContentWell.module.scss";

const LazyCoachContent = dynamic(
    () => import("./CoachContent").then((x) => x.CoachContent),
    { ssr: false }
);

const LazyAdviceContent = dynamic(
    () => import("./AdviceContent").then((x) => x.AdviceContent),
    { ssr: false }
);

const LazyRecentArticles = dynamic <IRecentArticles>(
    () => import("./RecentArticles").then((x) => x.RecentArticles),
    { ssr: false }
);

const LazyCompanyLogos = dynamic(
    () => import("./CompanyLogos").then((x) => x.CompanyLogos),
    { ssr: false }
);

const LazyNewsletter = dynamic(
    () => import("./NewsLetter").then((x) => x.Newsletter),
    { ssr: false }
);

const LazyTrendingSearch = dynamic(
    () => import("components/TrendingSearch").then((x) => x.TrendingSearch),
    { ssr: false }
);

const LazyCulture = dynamic(
    () => import("./Culture").then((x) => x.Culture),
    { ssr: false }
);

const LazyExploreCompanies = dynamic<IExploreCompanies>(
    () => import("./ExploreCompanies").then((x) => x.ExploreCompanies),
    { ssr: false }
);

const ContentWell = (props: { featuredCompanies: IFeaturedCompanies; recentArticles: IArticleTile[]; }) => {
    const { publicRuntimeConfig: { museHost } } = getConfig();
    const { featuredCompanies, recentArticles } = props;
    const hasMultipleFeaturedCompanies: boolean = featuredCompanies?.featuredCompanies2?.length > 0
        || featuredCompanies?.featuredCompanies3?.length > 0;

    useEffect(() => {
        window.dataLayer.push({
            event: "homepageLoaded",
            page: "/",
            path: "/"
        });
    }, []);

    return (
        <div className={styles.contentWell}>
            <HomepageHeader />
            {featuredCompanies
                && (
                    <ExploreCompanies {...{
                        featureHeading: featuredCompanies?.featuredCompaniesHeading1,
                        exploreCompaniesSubheading: featuredCompanies?.exploreCompaniesSubheading,
                        companies: featuredCompanies?.featuredCompanies1,
                        heading: featuredCompanies?.exploreCompaniesHeading,
                    }}
                    />
                )}
            <LazyCulture />
            <LazyNewsletter />
            <LazyCompanyLogos />
            {featuredCompanies
                && (
                    <LazyExploreCompanies {...{
                        featureHeading: featuredCompanies?.featuredCompaniesHeading2,
                        companies: featuredCompanies?.featuredCompanies2,
                    }}
                    />
                )}
            {featuredCompanies
                && (
                    <LazyExploreCompanies {...{
                        featureHeading: featuredCompanies?.featuredCompaniesHeading3,
                        companies: featuredCompanies?.featuredCompanies3,
                    }}
                    />
                )}
            {hasMultipleFeaturedCompanies && (
                <section className={styles.multipleCompanyButtonSection}>
                    <SquareButton
                        color="secondary-100"
                        className={styles.cta}
                        href={`${museHost}/companies`}
                    >
                        Explore Companies
                    </SquareButton>
                </section>
            )}
            <div className={styles.trendingSearch}>
                <LazyTrendingSearch {...{ type: "trendingSearchBlock" }} />
            </div>
            <LazyAdviceContent />
            <LazyRecentArticles {...{ recentArticles }} />
            <LazyCoachContent />
        </div>
    );
};
ContentWell.displayName = "ContentWell";
export { ContentWell };
