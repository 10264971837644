/* eslint-disable camelcase */
import React from "react";
import { PageLayout } from "@themuse/design-system";

import { useAppState } from "hooks/AppContext";

export const AppPageLayout = (props: React.ComponentProps<typeof PageLayout>) => {
    const { children, ...restProps } = props;
    const { user } = useAppState();
    const pageProps = {
        ...restProps,
        user
    };

    return (
        <PageLayout {...pageProps}>
            { children }
        </PageLayout>
    );
};
