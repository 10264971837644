import React, { useState } from "react";
import { UseComboboxStateChange } from "downshift";
import { v4 as uuidv4 } from "uuid";

import {
    ComposableHeaderDropdown, IndexedObject, Search
} from "@themuse/design-system";
import { CompanySuggestion, fetchHomepageCompanySuggestions } from "utils/api";
import { isBrowser } from "utils/browser";
import { triggerSnowplowSearchEvent, SnowplowSearchEventProps } from "@themuse/snowplow-js-client";

import styles from "./SearchDropdown.module.scss";

export const pushDataLayerEvent = (event: string, props: { [key: string]: any } = {}) => {
    if (isBrowser() && window.dataLayer) {
        window.dataLayer.push({ event, ...props });
    }
};

export const formatCompanySuggestions = (companySuggestions: CompanySuggestion[]) => companySuggestions
    .slice(0, 4).map((companyResult: CompanySuggestion) => {
        const { text, value } = companyResult;
        // eslint-disable-next-line camelcase
        const { short_name } = value;
        return {
            label: `${text}`,
            // eslint-disable-next-line camelcase
            value: `/profiles/${short_name}?_search_id=${uuidv4()}`
        };
    });

export const updateInput = async (
    changes: UseComboboxStateChange<IndexedObject>,
    setItems: (items: IndexedObject[]) => void,
    setInputValue: (inputValue: string) => void,
    fetchData:(apiBase: string, inputValue: string) => Promise<CompanySuggestion[]>
) => {
    const { inputValue } = changes;
    const jobSearchItem = {
        label: `${inputValue}`,
        value: `/search/keyword/${inputValue}/?_search_id=${uuidv4()}`,
    };
    setInputValue(inputValue);
    if (inputValue) {
        setItems([jobSearchItem]);
        const companyResults = await fetchData("", inputValue);
        const companyList = companyResults?.length ? formatCompanySuggestions(companyResults) : [];
        setItems([jobSearchItem].concat(companyList));
    } else {
        setItems([]);
    }
};

export const SearchDropdown = () => {
    const [dropdownItems, setDropdownItems] = useState([] as IndexedObject[]);
    const [inputValue, setInputValue] = useState("");

    const snowplowSearchEvent = (selectedItem: IndexedObject) => {
        const searchType = selectedItem?.value.startsWith("/profiles/") ? "company" : "job";
        // Note: Using location.origin because museApiOptions is not populated on error pages.
        const parsedUrl = new URL(selectedItem?.value || "", window.location.origin);
        const searchId = parsedUrl.searchParams.get("_search_id") || uuidv4();
        const eventProps: SnowplowSearchEventProps = {
            sp_search_type: searchType,
            sp_search_id: searchId,
            sp_search_keyword: selectedItem?.label
        };
        triggerSnowplowSearchEvent(eventProps);
    };

    const searchQuerySubmittedEvent = (selectedItemText: string) => {
        const searchTerm = selectedItemText || inputValue;
        const eventProps = {
            search_term: searchTerm || ""
        };
        pushDataLayerEvent("searchQuerySubmitted", eventProps);
    };

    const onKeyboardSelectItem = (_: React.KeyboardEvent<HTMLInputElement>, selectedItem: IndexedObject) => {
        if (!isBrowser()) return "";

        const searchPath = inputValue
            ? `/search/keyword/${inputValue}`
            : "/search";

        const path = selectedItem?.value
            ? selectedItem?.value
            : `${searchPath}?_search_id=${uuidv4()}`;

        searchQuerySubmittedEvent(selectedItem?.label);

        if (selectedItem?.value) {
            snowplowSearchEvent(selectedItem);
        } else {
            snowplowSearchEvent({
                label: inputValue,
                value: path
            });
        }

        return window.open(path, "_blank").focus();
    };

    const onMouseSelectItem = (_: React.MouseEvent<HTMLInputElement>, selectedItem: IndexedObject) => {
        searchQuerySubmittedEvent(selectedItem.label);
        snowplowSearchEvent(selectedItem);
    };

    const homepageClasses = {
        container: styles.container,
        containerOpened: styles.containerOpened,
        inputContainer: styles.inputContainer,
        inputContainerOpened: styles.inputContainerOpened,
        input: styles.input,
        menuOpened: styles.menuOpened,
        menuList: styles.menuList,
        listItem: styles.listItem,
        listItemText: styles.listItemText,
        inputClearButton: styles.inputClearButton,
        closeButton: styles.closeButton,
        postLabel: styles.postLabel,
    };

    const dropdownProps = {
        classes: homepageClasses,
        dropdownProps: {
            IconComponent: (<div><Search /></div>),
            debounceWait: 10,
            dropdownList: dropdownItems,
            inputId: "search-input",
            onInputChange: (changes: UseComboboxStateChange<IndexedObject>) => updateInput(
                changes,
                setDropdownItems,
                setInputValue,
                fetchHomepageCompanySuggestions
            ),
            noResultsMessage: " ",
            onKeyboardSelectItem,
            onMouseSelectItem
        }
    };

    return <ComposableHeaderDropdown {...dropdownProps} />;
};
