import { months } from "./constants";
import { MetadataObj } from "./objects";

/**
 * Convert a word to proper Case.
 */
const titleWordCase = (txt: string): string => `${txt.charAt(0).toUpperCase()}${txt.substr(1).toLowerCase()}`;

/**
 * Convert a sentence to proper Case.
 */
const titleCase = (txt: string): string => txt.split(" ").map(titleWordCase).join(" ");

/**
 * Converts a diacritic to normalized character plus accent. Then removes the accent.
 */
const normalizeAccents = (txt: string): string => txt.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

/**
 * Convert number to string and add a comma for thousands and millions.
 */
const formatNumberComma = (value: number) => new Intl.NumberFormat("en-US").format(value);

/**
 * generate the canonical URL.
 */
const getCanonicalUrl = (asPath: string = "/"): string => {
    const preface = "https://www.themuse.com";
    const path = asPath.split("?")[0];
    return preface + path;
};
/**
 * Returns true for "true", "True", "TRUE" and "1".
 * @param string string
 * @returns boolean
 */
const isTruthy = (string: string) => /^(true|1)$/.test(string.toLowerCase());

const parsePath = (urlPath: string) => urlPath.replace(/^\/(search\/)?/, "")
    .split("/").reduce((values, pathPart, index, shortenedPath): MetadataObj => {
        const localValues = values;

        if (index % 2) {
            localValues[shortenedPath[index - 1]] = pathPart;
        } else {
            localValues[pathPart] = "";
        }

        return localValues;
    }, {} as MetadataObj);

/**
 *  Rename the object names to query param names
 */
const objToQueryParams = (obj: MetadataObj): string => {
    const mappedObj = Object.keys(obj).reduce((acc, key) => ({
        ...acc,
        ...{ [key]: encodeURIComponent(obj[key]) }
    }), {} as MetadataObj);

    const queryParamStr = Object.keys(mappedObj)
        .filter((key) => {
            if (mappedObj[key] && mappedObj[key].length > 0) {
                return key;
            }
            return null;
        })
        .map((key: string) => `${key}/${mappedObj[key] || ""}`)
        .join("/");
    return queryParamStr;
};

/**
 * Convert the query string into an object
 */
const queryStringToObj = (searchString: string): MetadataObj => {
    const pairs: string[] = searchString.split("&");
    const result: MetadataObj = {};
    pairs.forEach((pair) => {
        const pairSplit: string[] = pair.split("=");
        if (pairSplit[0] in result) {
            // check for additional values and add it to the result[key]
            const splitValues = result[pairSplit[0]].split(",");
            splitValues.push(decodeURIComponent(pairSplit[1]));
            result[pairSplit[0]] = splitValues.join(",");
        } else {
            result[pairSplit[0]] = decodeURIComponent(pairSplit[1] || "");
        }
    });

    return result;
};

/**
 * Convert an object to query string removing nulls.
 */
const objToQueryString = (obj: MetadataObj): string => Object.keys(obj)
    .map((key: string) => `${key}=${obj[key] || ""}`)
    .join("&");

/**
 * Remove special characters and reduce to lower case with hyphens.
 */
const slugify = (text: string) => decodeURIComponent(text)
    .replace(/[^a-z0-9]/gmi, "-")
    .replace(/--/gmi, "-")
    .toLowerCase();

/**
 * Formats a date in correct format for job card
 */
const formatDate = (date: Date) => {
    const month = months[date.getMonth()];
    const day = date.getDate();

    return `${month} ${day}`;
};

function camelToSnakeCase(text: string): string {
    return text
        .replace(/(.)([A-Z][a-z]+)/, "$1_$2")
        .replace(/([a-z0-9])([A-Z])/, "$1_$2")
        .toLowerCase();
}

/**
 * Converts an id number to a string
 */
const getId = (id: any) => (id ? parseInt(id, 10) : undefined);

export {
    formatNumberComma,
    objToQueryString,
    queryStringToObj,
    getCanonicalUrl,
    isTruthy,
    titleCase,
    normalizeAccents,
    parsePath,
    objToQueryParams,
    slugify,
    formatDate,
    camelToSnakeCase,
    getId
};

export type { MetadataObj };
