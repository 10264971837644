import { GetServerSidePropsContext } from "next";
import { ParsedUrlQuery } from "querystring";
import getConfig from "next/config";

import { isTruthy } from "./strings";

/**
 * Take a name and determine if the feature flag is activated
 * based on environment variable and query params.
 */
const isFeatureFlagActive = (name: string, query: ParsedUrlQuery, config) => {
    if (name in query) {
        return isTruthy(query[name] as string);
    }
    if (config?.[name] !== undefined) {
        return config?.[name] === true || config?.[name] === "true";
    }
    return false;
};

/**
 * Returns a list of all existing flags in the config.
 */
const getFeatureFlags = () => {
    const { publicRuntimeConfig } = getConfig();
    return Object.keys(publicRuntimeConfig).reduce((prev, curr) => {
        if (/^FEATURE_FLAG/.test(curr)) {
            prev.push(curr);
        }
        return prev;
    }, []);
};

/**
 * Returns all active flags in the config and/or query params.
 */
const getActiveFeatureFlags = (context: GetServerSidePropsContext) => {
    const { query } = context;
    const { publicRuntimeConfig } = getConfig();
    const featureFlagsInQuery = getFeatureFlags().reduce((featureFlags, featureFlag) => ({
        ...featureFlags,
        [featureFlag]: isFeatureFlagActive(featureFlag, query, publicRuntimeConfig)
    }), {});
    return featureFlagsInQuery;
};

export {
    isFeatureFlagActive,
    getFeatureFlags,
    getActiveFeatureFlags
};
