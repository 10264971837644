import React from "react";
import Script from "next/script";
import { useAppState } from "hooks/AppContext";

// All the ad types/positions there are.
const allAds = [
    "leaderboard",
    "sidebar-atf",
    "sidebar-btf",
    "content-desktop",
    "content-mobile",
    "adhesion-mobile",
    "adhesion-tablet",
    "adhesion-desktop",
    "recipe",
    "auto-insert-sticky",
    "in-image",
    "chicory",
    "zergnet",
    "interstitial-mobile",
    "interstitial-desktop",
    "universal-player-desktop",
    "universal-player-mobile"
];

/**
 * Renders MediaVine ads based on the allowList provided.
 * If the URL parameter '?hide-ads' is present, ads will not be displayed.
 * if there are no members of allowList, ads will not be displayed
 *
 * @param props - Object containing the allowList array.
 * @returns JSX element with MediaVine ads or null if ads are not to be displayed.
 */
export const MediaVine = (props: { allowList: string[]; }) => {
    const { allowList = [] } = props;
    const { pageMetaData: { pathName } } = useAppState();

    // Remove items that should be allowed from the blockList.
    const blockList = allAds.filter((adType: string) => !allowList.includes(adType));

    // Convert the list into a set of data attributes.
    const blockAttributes = blockList.reduce((obj, item) => ({ ...obj, [`data-blocklist-${item}`]: "1" }), {});

    // Do not show ads if we have the query string
    // Do not show ads if we have no allowed slots.
    return !pathName.includes("hide-ads") && !!allowList.length ? (
        <>
            <Script
                id="mediavine"
                strategy="lazyOnload"
                data-noptimize="1"
                data-cfasync="false"
                src="//scripts.mediavine.com/tags/the-muse.js"
            />
            <div
                id="mediavine-settings"
                {...blockAttributes}
            />
        </>
    ) : null;
};
