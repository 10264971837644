import React from "react";

import { getFeatureFlags } from "utils/featureFlags";

interface FeatureFlags {
    [key: string]: boolean
}

interface FeatureFlagsContextProps {
    featureFlags: FeatureFlags;
}

const defaultFeatureFlagsState = Object.keys(getFeatureFlags()).reduce((featureFlags, featureFlag): FeatureFlags => ({
    ...featureFlags,
    [featureFlag]: false
}), {} as FeatureFlags);

const FeatureFlagsContext = React.createContext<FeatureFlags | undefined>(defaultFeatureFlagsState);

const FeatureFlagsProvider: React.FC<React.PropsWithChildren<FeatureFlagsContextProps>> = (props) => {
    const { featureFlags, children } = props;

    return (
        <FeatureFlagsContext.Provider value={featureFlags}>
            {children}
        </FeatureFlagsContext.Provider>
    );
};

const useFeatureFlagsState = () => {
    const context = React.useContext(FeatureFlagsContext);
    if (context === undefined) {
        throw new Error("useFeatureFlagsState must be used within the FeatureFlagsProvider");
    }
    return context;
};

FeatureFlagsProvider.displayName = "FeatureFlagsProvider";
useFeatureFlagsState.displayName = "useFeatureFlagsState";

export { FeatureFlagsProvider, useFeatureFlagsState };
