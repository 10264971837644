/* eslint-disable max-len */
import React from "react";
import getConfig from "next/config";

import { AnimationContainer } from "./AnimationContainer";
import { SearchDropdown } from "./SearchDropdown";

import styles from "./HomepageHeader.module.scss";

const baseHeroImage = "https://advice-assets.themuse.com/advice-static/images/homepage-hero-image.jpg";
const getHeroImage = (height: number, width: number) => {
    const { publicRuntimeConfig: { pilboxHost } } = getConfig();
    const flags = "filter=antialias&mode=crop&opt=1&pos=center&prog=1&q=keep";
    const result = `${pilboxHost}/image.png?${flags}&url=${baseHeroImage}&h=${height}&w=${width}`;
    return result;
};

const HomepageHeader = () => (
    <div className={styles.headerContainer}>
        <div className={styles.left}>
            <div>
                <AnimationContainer>
                    <h1 className={styles.h1}>
                        Find work
                        <br />
                        {" "}
                        that&apos;s
                        {" "}
                        <span>worth it.</span>
                    </h1>
                    <p className={styles.subtext}>
                        Make a career match where the people, perks and values align with your needs.
                    </p>
                </AnimationContainer>
                <SearchDropdown />
            </div>
        </div>
        <div className={styles.right}>
            <div className={styles.images}>
                <div className={styles.stars} />
                <img
                    alt=""
                    className={styles.hero}
                    src="https://advice-assets.themuse.com/advice-static/images/homepage-hero-image.jpg"
                    srcSet={`${getHeroImage(200, 133)} 375w, ${getHeroImage(364, 301)} 768w, ${baseHeroImage} 1024w`}
                />
            </div>
        </div>
    </div>
);

export { HomepageHeader };
